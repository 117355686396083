export const IS_ENV_WITH_LOCAL_ASSETS = false;

export function getLocalAssets(): Record<string, string> {
  return {};
}

export function getManifest2() {
  return {};
}

// Compute manifest base URL if available
export const manifestBaseUrl = null;
